// generated by parseCompaniesCsv.js
// DO NOT EDIT MANUALLY. ONLY GENERATE USING 'npm run parse-companies'
export const companies = JSON.parse(`[
  {
    "companyName": "AEM Creations",
    "focuses": "Animation",
    "positionTypes": [
      "Interns",
      "Contract workers",
      "Freelancers"
    ],
    "website": "https://www.aem2create.com/",
    "interviews": "No",
    "virtualTimes": "All Day",
    "inPersonTimes": "All Day",
    "logoUrlCheckString": "https://www.aem2create.com/website-samples/images/logo.png"
  },
  {
    "companyName": "Netflix Feature Animation",
    "focuses": "Animation",
    "positionTypes": [
      ""
    ],
    "website": "https://www.netflixanimation.com/",
    "interviews": "Yes",
    "virtualTimes": "All Day",
    "inPersonTimes": "",
    "logoUrlCheckString": "https://brand.netflix.com/en/assets/logos"
  }
]`);

// edit manually
export const pastCompanies: string[] = Array.from(new Set([
  "900lbs",
  "A Bunch Of Short Guys",
  "AEM Creations",
  "Aggieland Outfitters",
  "Bluepoint Games",
  "BonusXP",
  "Booz Allen Hamilton, Inc.",
  "C.C. Creations",
  "Cinevva",
  "Click Here Labs - TRG",
  "Cloud Imperium Games",
  "Credera",
  "Deloitte",
  "Digital Seat Media",
  "DreamWorks Animation",
  "Electronic Arts",
  "Framestore",
  "Game Worlds",
  "Gearbox Software",
  "IMG Studio",
  "Jacobs Architects",
  "Mighty Coconut",
  "Pixar",
  "Powerhouse Animation",
  "ReelFX",
  "Six Foot Games",
  "Texas Film Commission",
  "Texas Moving Picture Alliance (TXMPA)",
  "The Third Floor, Inc.",
  "TRG",
  "Walt Disney",
  "Viasat",
]));
pastCompanies.sort((a, b) => a.toLowerCase() <= b.toLowerCase() ? -1 : 1);
